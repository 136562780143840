var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "background-color": "#F2F2F2",
      "width": "100vw",
      "height": "100vh"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#FFFFFF",
      "width": "100vw",
      "height": "12.82vw",
      "font-size": "15px",
      "font-weight": "bold",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_vm._v("Pay Result")]), _vm.addressInfo.consignee !== undefined ? _c('div', {
    staticStyle: {
      "border-radius": "10px",
      "overflow": "hidden",
      "margin-left": "2.56vw",
      "margin-top": "2vw",
      "width": "94.87vw",
      "height": "44.05vw",
      "background-color": "#FFFFFF"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "90.87vw",
      "height": "10.68vw",
      "margin-left": "4vw",
      "margin-top": "2vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.addressInfo.consignee) + "   " + _vm._s(_vm.addressInfo.phone) + " ")]), _c('div', {
    staticStyle: {
      "width": "90.87vw",
      "height": "6.68vw",
      "margin-left": "4vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.addressInfo.addressLine2) + "   " + _vm._s(_vm.addressInfo.addressLine1) + " ")]), _c('div', {
    staticStyle: {
      "width": "90.87vw",
      "height": "10.68vw",
      "margin-left": "4vw",
      "margin-bottom": "2vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.addressInfo.city) + "   " + _vm._s(_vm.addressInfo.state) + "   " + _vm._s(_vm.addressInfo.postalCode) + " ")]), _c('div', {
    staticStyle: {
      "width": "90.87vw",
      "height": "10.68vw",
      "margin-left": "4vw",
      "margin-bottom": "2vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_c('van-button', {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "bold",
      "width": "39.74vw",
      "border-radius": "8px"
    },
    attrs: {
      "color": "#D56460"
    },
    on: {
      "click": function ($event) {
        return _vm.editOrderAddress();
      }
    }
  }, [_vm._v("Address Wrong")]), _c('van-button', {
    staticStyle: {
      "font-size": "14px",
      "font-weight": "bold",
      "width": "39.74vw",
      "border-radius": "8px",
      "margin-left": "7.39vw"
    },
    attrs: {
      "color": "#619F5B"
    },
    on: {
      "click": function ($event) {
        return _vm.goToOrderDetail();
      }
    }
  }, [_vm._v("Address Correct")])], 1)]) : _vm._e(), _c('div', {
    staticStyle: {
      "border-radius": "10px",
      "overflow": "hidden",
      "margin-left": "2.56vw",
      "margin-top": "2vw",
      "width": "94.87vw",
      "height": "44.05vw",
      "background-color": "#FFFFFF"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "28px",
      "font-weight": "bold",
      "color": "#D56460",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "width": "94.87vw",
      "height": "22.03vw",
      "background-color": "#FFFFFF"
    }
  }, [_vm._v("Success")]), _c('div', {
    staticStyle: {
      "width": "94.87vw",
      "height": "12.82vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('van-button', {
    staticStyle: {
      "width": "38.46vw",
      "height": "12.82vw",
      "border-radius": "10px",
      "font-size": "20px"
    },
    attrs: {
      "color": "#619F5B"
    },
    on: {
      "click": function ($event) {
        return _vm.goToOrderDetail();
      }
    }
  }, [_vm._v("View Order")])], 1)]), _vm.editAddressFlag ? _c('edit-address-popup', {
    attrs: {
      "old-address": _vm.oldAddress,
      "edit-address-popup-show": _vm.editAddressFlag
    },
    on: {
      "callbackForEdit": _vm.callbackForEdit,
      "callbackForCancelEdit": _vm.callbackForCancelEdit
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };